self.deprecationWorkflow = self.deprecationWorkflow || {};
self.deprecationWorkflow.config = {
  workflow: [
    //resolved, but still throws warning
    // { handler: 'silence', matchId: 'ember.built-in-components.import' },
    { handler: 'silence', matchId: 'ember.globals-resolver' },
    {
      handler: 'silence',
      matchId: 'deprecated-run-loop-and-computed-dot-access',
    },
    { handler: 'silence', matchId: 'ember-metal.get-with-default' },
    { handler: 'silence', matchId: 'computed-property.override' },
    { handler: 'silence', matchId: 'implicit-injections' },
    { handler: 'silence', matchId: 'ember-source.deprecation-without-for' },
    { handler: 'silence', matchId: 'ember-source.deprecation-without-since' },
    { handler: 'silence', matchId: 'ember-simple-auth.session.authorize' },
    { handler: 'silence', matchId: 'ember-simple-auth.baseAuthorizer' },
    { handler: 'silence', matchId: 'route-render-template' },
    { handler: 'silence', matchId: 'this-property-fallback' },
    {
      handler: 'silence',
      matchId: 'ember-views.curly-components.jquery-element',
    },
    { handler: 'silence', matchId: 'ember-data:default-serializer' },
    //resolved
    // {
    //   handler: 'throw',
    //   matchId: 'ember.built-in-components.legacy-attribute-arguments',
    // },
    //resolved
    // {
    //   handler: 'throw',
    //   matchId: 'ember-views.event-dispatcher.mouseenter-leave-move',
    // },
  ],
};
