define('ember-dompurify/dompurify-hooks/target-blank', ['exports', 'ember-dompurify/hook'], function (exports, _hook) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  class TargetBlankHook extends _hook.default {
    afterSanitizeAttributes(node) {
      if (node instanceof HTMLAnchorElement) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener');
      }
    }
  }
  exports.default = TargetBlankHook;
});