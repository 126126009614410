define("ember-lifeline/debounce-task", ["exports", "@ember/debug", "@ember/runloop", "@ember/destroyable"], function (_exports, _debug, _runloop, _destroyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cancelDebounce = cancelDebounce;
  _exports.debounceTask = debounceTask;
  /**
   * A map of instances/debounce functions that allows us to
   * store pending debounces per instance.
   *
   * @private
   */
  const registeredDebounces = new WeakMap();

  /**
   * Runs the function with the provided name after the timeout has expired on the last
   * invocation. The timer is properly canceled if the object is destroyed before it is
   * invoked.
   *
   * Example:
   *
   * ```js
   * import Component from 'ember-component';
   * import { debounceTask, runDisposables } from 'ember-lifeline';
   *
   * export default Component.extend({
   *   logMe() {
   *     console.log('This will only run once every 300ms.');
   *   },
   *
   *   click() {
   *     debounceTask(this, 'logMe', 300);
   *   },
   *
   *   willDestroy() {
   *     this._super(...arguments);
   *
   *     runDisposables(this);
   *   }
   * });
   * ```
   *
   * @function debounceTask
   * @param { IDestroyable } destroyable the instance to register the task for
   * @param { String } name the name of the task to debounce
   * @param { ...* } debounceArgs arguments to pass to the debounced method
   * @param { Number } spacing the amount of time to wait before calling the method (in milliseconds)
   * @param { Boolean } [immediate] Trigger the function on the leading instead of the trailing edge of the wait interval. Defaults to false.
   * @public
   */
  function debounceTask(destroyable, name) {
    (true && !(typeof name === 'string') && (0, _debug.assert)(`Called \`debounceTask\` without a string as the first argument on ${destroyable}.`, typeof name === 'string'));
    (true && !(typeof destroyable[name] === 'function') && (0, _debug.assert)(`Called \`destroyable.debounceTask('${name}', ...)\` where 'destroyable.${name}' is not a function.`, typeof destroyable[name] === 'function'));
    if (destroyable.isDestroying) {
      return;
    }
    for (var _len = arguments.length, debounceArgs = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      debounceArgs[_key - 2] = arguments[_key];
    }
    const lastArgument = debounceArgs[debounceArgs.length - 1];
    const spacing = typeof lastArgument === 'boolean' ? debounceArgs[debounceArgs.length - 2] : lastArgument;
    (true && !(typeof spacing === 'number') && (0, _debug.assert)(`Called \`debounceTask\` with incorrect \`spacing\` argument. Expected Number and received \`${spacing}\``, typeof spacing === 'number'));
    let pendingDebounces = registeredDebounces.get(destroyable);
    if (!pendingDebounces) {
      pendingDebounces = new Map();
      registeredDebounces.set(destroyable, pendingDebounces);
      (0, _destroyable.registerDestructor)(destroyable, getDebouncesDisposable(pendingDebounces));
    }
    let debouncedTask;
    if (!pendingDebounces.has(name)) {
      debouncedTask = function () {
        pendingDebounces.delete(name);
        destroyable[name](...arguments);
      };
    } else {
      debouncedTask = pendingDebounces.get(name).debouncedTask;
    }

    // cancelId is new, even if the debounced function was already present
    let cancelId = (0, _runloop.debounce)(destroyable, debouncedTask, ...debounceArgs);
    pendingDebounces.set(name, {
      debouncedTask,
      cancelId
    });
  }

  /**
   * Cancel a previously debounced task.
   *
   * Example:
   *
   * ```js
   * import Component from 'ember-component';
   * import { debounceTask, cancelDebounce } from 'ember-lifeline';
   *
   * export default Component.extend({
   *   logMe() {
   *     console.log('This will only run once every 300ms.');
   *   },
   *
   *   click() {
   *     debounceTask(this, 'logMe', 300);
   *   },
   *
   *   disable() {
   *      cancelDebounce(this, 'logMe');
   *   },
   *
   *   willDestroy() {
   *     this._super(...arguments);
   *
   *     runDisposables(this);
   *   }
   * });
   * ```
   *
   * @function cancelDebounce
   * @param { IDestroyable } destroyable the instance to register the task for
   * @param { String } methodName the name of the debounced method to cancel
   * @public
   */
  function cancelDebounce(destroyable, name) {
    if (!registeredDebounces.has(destroyable)) {
      return;
    }
    const pendingDebounces = registeredDebounces.get(destroyable);
    if (!pendingDebounces.has(name)) {
      return;
    }
    const {
      cancelId
    } = pendingDebounces.get(name);
    pendingDebounces.delete(name);
    (0, _runloop.cancel)(cancelId);
  }
  function getDebouncesDisposable(debounces) {
    return function () {
      if (debounces.size === 0) {
        return;
      }
      debounces.forEach(p => (0, _runloop.cancel)(p.cancelId));
    };
  }
});