define("@ember/legacy-built-in-components/index", ["exports", "@embroider/macros/es-compat", "@embroider/macros/runtime", "ember"], function (_exports, _esCompat, _runtime, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TextField = _exports.TextArea = _exports.LinkComponent = _exports.Checkbox = void 0;
  /* eslint-disable ember/new-module-imports */

  let Checkbox;
  _exports.Checkbox = Checkbox;
  if ((0, _runtime.macroCondition)(true)) {
    _exports.Checkbox = Checkbox = (0, _esCompat.default)(require("@ember/legacy-built-in-components/components/checkbox")).default;
  } else if ((0, _runtime.macroCondition)(true)) {
    _exports.Checkbox = Checkbox = _ember.default._LegacyCheckbox.extend();
  } else {
    _exports.Checkbox = Checkbox = _ember.default.Checkbox;
  }
  let LinkComponent;
  _exports.LinkComponent = LinkComponent;
  if ((0, _runtime.macroCondition)(true)) {
    _exports.LinkComponent = LinkComponent = (0, _esCompat.default)(require("@ember/legacy-built-in-components/components/link-to")).default;
  } else if ((0, _runtime.macroCondition)(true)) {
    _exports.LinkComponent = LinkComponent = _ember.default._LegacyLinkComponent.extend();
  } else {
    _exports.LinkComponent = LinkComponent = _ember.default.LinkComponent;
  }
  let TextArea;
  _exports.TextArea = TextArea;
  if ((0, _runtime.macroCondition)(true)) {
    _exports.TextArea = TextArea = (0, _esCompat.default)(require("@ember/legacy-built-in-components/components/textarea")).default;
  } else if ((0, _runtime.macroCondition)(true)) {
    _exports.TextArea = TextArea = _ember.default._LegacyTextArea.extend();
  } else {
    _exports.TextArea = TextArea = _ember.default.TextArea;
  }
  let TextField;
  _exports.TextField = TextField;
  if ((0, _runtime.macroCondition)(true)) {
    _exports.TextField = TextField = (0, _esCompat.default)(require("@ember/legacy-built-in-components/components/text-field")).default;
  } else if ((0, _runtime.macroCondition)(true)) {
    _exports.TextField = TextField = _ember.default._LegacyTextField.extend();
  } else {
    _exports.TextField = TextField = _ember.default.TextField;
  }
});