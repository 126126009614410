define("ember-user-activity/services/user-idle", ["exports", "ember", "ember-user-activity/services/-private/event-manager", "@ember/service", "@ember/runloop", "@glimmer/tracking"], function (_exports, _ember, _eventManager, _service, _runloop, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let UserIdleService = (_dec = (0, _service.inject)('ember-user-activity@user-activity'), (_class = class UserIdleService extends _eventManager.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "userActivity", _descriptor, this);
      _defineProperty(this, "_debouncedTimeout", null);
      _defineProperty(this, "activeEvents", ['userActive']);
      _defineProperty(this, "IDLE_TIMEOUT", 600000);
      _initializerDefineProperty(this, "isIdle", _descriptor2, this);
    }
    _setupListeners(method) {
      this.activeEvents.forEach(event => {
        this.userActivity[method](event, this, this.resetTimeout);
      });
    }

    // TODO: migrate to constructor
    // eslint-disable-next-line ember/classic-decorator-hooks
    init() {
      super.init(...arguments);
      if (_ember.default.testing) {
        // Shorter debounce in testing mode
        this.IDLE_TIMEOUT = 10;
      }
      this._setupListeners('on');
      this.resetTimeout();
    }
    willDestroy() {
      this._setupListeners('off');
      if (this._debouncedTimeout) {
        (0, _runloop.cancel)(this._debouncedTimeout);
      }
      super.willDestroy(...arguments);
    }
    resetTimeout() {
      let oldIdle = this.isIdle;
      this.isIdle = false;
      if (oldIdle) {
        this.trigger('idleChanged', false);
      }
      this._debouncedTimeout = (0, _runloop.debounce)(this, this.setIdle, this.IDLE_TIMEOUT);
    }
    setIdle() {
      if (this.isDestroyed) {
        return;
      }
      this.isIdle = true;
      this.trigger('idleChanged', true);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "userActivity", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isIdle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
  _exports.default = UserIdleService;
});