define("ember-intl/-private/formatters/format-relative", ["exports", "@ember/debug", "fast-memoize", "intl-messageformat", "ember-intl/-private/error-types", "ember-intl/-private/formatters/-base"], function (_exports, _debug, _fastMemoize, _intlMessageformat, _errorTypes, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const RELATIVE_TIME_OPTIONS = ['numeric', 'style', 'unit'];

  /**
   * @private
   * @hide
   */
  class FormatRelative extends _base.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "createNativeFormatter", (0, _fastMemoize.default)((locales, options) => {
        if (!Intl || !Intl.RelativeTimeFormat) {
          const error = new _intlMessageformat.FormatError(`Intl.RelativeTimeFormat is not available in this environment. Try polyfilling it using "@formatjs/intl-relativetimeformat"`, _errorTypes.MISSING_INTL_API);
          this.config.onError({
            kind: _errorTypes.MISSING_INTL_API,
            error
          });
          throw error;
        }
        return new Intl.RelativeTimeFormat(locales, options);
      }));
    }
    get options() {
      return RELATIVE_TIME_OPTIONS;
    }
    format(locale, value, formatOptions) {
      const formatterOptions = this.readOptions(formatOptions);
      this.validateFormatterOptions(locale, formatterOptions);
      const unit = formatOptions?.unit ?? formatterOptions.unit;
      (true && !(unit) && (0, _debug.assert)(`[ember-intl] FormatRelative: 'formatOptions' are missing a 'unit'.`, unit));
      const formatterInstance = this.createNativeFormatter(locale, formatterOptions);
      return formatterInstance.format(typeof value === 'number' ? value : new Date(value).getTime(), unit);
    }
  }
  _exports.default = FormatRelative;
  _defineProperty(FormatRelative, "type", 'relative');
});